import type { FC } from 'react'

import { QrCodeStyled } from '@components/QrCode/styles'

type Props = {
  size?: number
  url: string
}

export const QrCode: FC<Props> = ({ size = 64, url }) => {
  const sizeForUpload = size * 1.6

  return (
    <QrCodeStyled
      $size={size}
      alt="apps qr code"
      height={sizeForUpload}
      quality={100}
      src={url}
      width={sizeForUpload}
    />
  )
}
